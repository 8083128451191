export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  s3: {
    REGION: "eu-west-1",
    BUCKET: "schrama-notes-app-uploads"
  },
  apiGateway: {
    REGION: "eu-west-1",
    URL: "https://n5qlgppbab.execute-api.eu-west-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: "eu-west-1",
    USER_POOL_ID: "eu-west-1_a4XKuPFTI",
    APP_CLIENT_ID: "2tls64mnt88p1buotrev4e2q1s",
    IDENTITY_POOL_ID: "eu-west-1:ed898a0c-e0c7-4614-9176-14d662c30184"
  }
};
